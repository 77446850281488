import React, { useState, useEffect } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import updateUser from '../../api/update-user';
import getUser from '../../api/get-user';
import { RootState } from '../..';

interface UserSettingProps {
  label: string;
  field: string;
  uid: string;
}

function UserSetting({ label, field, uid }: UserSettingProps) {
  const [subscriptions, setSubscriptions] = useState<{ [key: string]: boolean }>({
    karla_mail: false,
    karla_search: false,
    karla_insights: false,
    karla_support: false,
    karla_chat: false,
  });

  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state: RootState) => state.user);

  useEffect(() => {
    const fetchUserData = async () => {
      console.log('Fetching user data for uid:', uid);
      try {
        await getUser({ uid }, dispatch); // Fetch the user data from API

        if (user && user.billing_info) {
          const fetchedSubscriptions = user.billing_info.subscriptions || {};
          console.log('see here for subs', fetchedSubscriptions);
          // Merge the default state with the new fetched subscriptions
          setSubscriptions({
            karla_mail: fetchedSubscriptions.karla_mail ?? false,
            karla_search: fetchedSubscriptions.karla_search ?? false,
            karla_insights: fetchedSubscriptions.karla_insights ?? false,
            karla_support: fetchedSubscriptions.karla_support ?? false,
            karla_chat: fetchedSubscriptions.karla_chat ?? false,
          });
        } else {
          console.error('No billing info found in user data.');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (uid) {
      fetchUserData();
    }
    console.log('User data:', user);
  }, [uid, dispatch, user]);

  const handleChange = (field: string) => async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const updatedSubscriptions = {
      ...subscriptions,
      [field]: checked,
    };

    setSubscriptions(updatedSubscriptions); // Update the local state

    const updatedBillingInfo = {
      ...user.billing_info, // Preserve the existing info
      subscriptions: updatedSubscriptions,
    };
    console.log('billing info ', user.billing_info);

    const payload = {
      uid,
      data: {
        billing_info: updatedBillingInfo,
      },
      existingData: user,
    };

    try {
      console.log('Sending payload to update user:', payload);
      const response = await dispatch(updateUser(payload));
      console.log('Response from backend:', response);
      console.log('User data after update:', user);
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error('Error from backend:', error.message);
      } else {
        console.error('An unknown error occurred.');
      }
    }
  };

  return (
    <div>
      {loading && <p>Loading user settings...</p>}
      {!loading && (
        <FormGroup>
          <FormControlLabel control={<Switch checked={subscriptions.karla_mail} onChange={handleChange('karla_mail')} />} label="Karla Mail" />
          <FormControlLabel control={<Switch checked={subscriptions.karla_search} onChange={handleChange('karla_search')} />} label="Karla Search" />
          <FormControlLabel control={<Switch checked={subscriptions.karla_insights} onChange={handleChange('karla_insights')} />} label="Karla Insights" />
          <FormControlLabel control={<Switch checked={subscriptions.karla_support} onChange={handleChange('karla_support')} />} label="Karla Support" />
          <FormControlLabel control={<Switch checked={subscriptions.karla_chat} onChange={handleChange('karla_chat')} />} label="Karla Chat" />
        </FormGroup>
      )}
    </div>
  );
}

export default UserSetting;
