import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../hooks/redux';
import { getDocument, updateDocument } from '../hooks/firestore';

import LeftPanel from '../components/sections/left-panel';
import ContentPanel from '../components/sections/content-panel';

import Messages from '../components/conversations/Messages';
import Info from '../components/conversations/Info';

import getUser from '../api/get-user';
import getDatastreams from '../api/get-datastreams';
import CollectData from '../components/datastreams/CollectData';
import ModelList from '../components/models/ModelList';
import DailyInfo from '../components/conversations/DailyInfo';
import DatastreamList from '../components/datastreams/DatastreamList';

const Customer = () => {
  const dispatch = useAppDispatch();
  const { org_id } = useParams();
  const organization = useAppSelector((state) => state.organization);
  const datastreams = useAppSelector((state) => state.datastreams);

  useEffect(() => {
    if (org_id && !organization) {
      const getUserThunk = getUser({ uid: org_id }, dispatch);

      dispatch(getUserThunk);
    }
  }, [org_id]);

  useEffect(() => {
    if (organization && !datastreams) {
      const getDatastreamsThunk = getDatastreams({ uid: org_id }, dispatch);

      dispatch(getDatastreamsThunk);
    }
  }, [organization]);

  return (
    <div className="app conversation">
      <LeftPanel />

      <ContentPanel title={organization?.metadata?.company_name}>
        <DailyInfo uid={organization?.id} />
      </ContentPanel>
    </div>
  );
};

export default Customer;
