import { Box, MenuItem, Select, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import ContentPanelField from '../components/conversations/ContentPanelField';
import PrimaryButton from '../components/button/PrimaryButton';
import { API_ENDPOINTS } from '../utils/constants';
import axios from 'axios';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useAppSelector } from '../hooks/redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

interface Props {
  organization: any;
}

function BillingInfo({ organization }: Props) {
  const dayjsNow = dayjs(dayjs().format('DD-MM-YYYY'));
  const [billingIndex, setBillingIndex] = useState(0);
  const [billingPlan, setBillingPlan] = useState('basic');
  const [billingDate, setBillingDate] = useState<Dayjs | null>(dayjsNow);
  const [billingConversation, setBillingConversation] = useState(150);
  const [billingPrice, setBillingPrice] = useState(0);
  const [billingIntervalUnit, setBillingIntervalUnit] = useState('month');
  const [billingIntervalValue, setBillingIntervalValue] = useState(1);
  const [checkedActiveCostumerStatus, setActiveCostumerStatus] = useState(organization?.billing_info?.active_costumer || false);

  const billingIntervalUnits = [
    {
      name: 'Day',
      value: 'day',
    },
    {
      name: 'Month',
      value: 'month',
    },
    {
      name: 'Year',
      value: 'year',
    },
  ];
  const billingOptions = [
    {
      name: 'Basic plan',
      value: 'basic',
      id: 0,
      price: 950,
      conversations: 150,
    },
    {
      name: 'Plus plan',
      value: 'plus',
      id: 1,
      price: 1750,
      conversations: 300,
    },
    {
      name: 'Pro plan',
      value: 'pro',
      id: 2,
      price: 2450,
      conversations: 500,
    },
    {
      name: 'Scale plan',
      value: 'scale',
      id: 3,
      price: 4450,
      conversations: 1000,
    },
    {
      name: 'Custom plan',
      value: 'custom',
      id: 4,
      price: 'Custom',
      conversations: 'Custom',
    },
  ];

  useEffect(() => {
    if (organization.billing_info) {
      setBillingPlan(organization.billing_info.plan);
      setBillingDate(dayjs(organization.billing_info.start_date, 'DD-MM-YYYY'));
      setBillingConversation(organization.billing_info.included_conversations);
      setBillingPrice(organization.billing_info.price);
      setBillingIntervalValue(organization.billing_info.interval_value);
      setBillingIntervalUnit(organization.billing_info.interval_unit);
      const selectedIndex = billingOptions.findIndex((option) => option.value === organization.billing_info.plan);
      setBillingIndex(selectedIndex);
    }
  }, [organization]);

  const onSaveClick = async () => {
    const settings = {
      plan: billingPlan,
      start_date: billingDate?.format('DD-MM-YYYY').toString(),
      interval_value: billingIntervalValue,
      interval_unit: billingIntervalUnit,
      included_conversations: billingPlan === 'custom' ? billingConversation : billingOptions[billingIndex].conversations,
      price: billingPlan === 'custom' ? billingPrice : billingOptions[billingIndex].price,
      active_costumer: checkedActiveCostumerStatus,
    };
    organization.billing_info = settings;
    const params = {
      uid: organization.id,
      data: organization,
    };

    const response = await axios.post(`${API_ENDPOINTS.KARLA_FLASK_API}/karla/operations/users/update`, {
      params: params,
      // headers: {
      // 	"Authorization" : `Bearer ${ACCESS_TOKEN}`
      // }
    });
  };
  const handleActiveCostumerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveCostumerStatus(event.target.checked);

    onSaveClick();
  };

  return (
    <>
      <h1 style={{ margin: 0, marginTop: 10, fontSize: 30 }}> Billing information</h1>
      <p>Here you can see/select what plan this user is on, how much they are to be billed, when their next billing is and more.</p>
      <br />
      <FormControlLabel control={<Switch checked={checkedActiveCostumerStatus} onChange={handleActiveCostumerChange} inputProps={{ 'aria-label': 'controlled' }} />} label="Activate Costumer" />
      <br />
      <br />
      Current plan: {/*billingOptions[parseInt(value)-1].name*/}
      <Select
        labelId="Billing-options"
        id="basic-menu"
        value={billingPlan}
        label="Billing options"
        key="billing-options-select"
        onChange={(event) => {
          setBillingPlan(event.target.value);
          const selectedIndex = billingOptions.findIndex((option) => option.value === event.target.value);
          setBillingIndex(selectedIndex);
        }}
      >
        {billingOptions.map((lang: any) => (
          <MenuItem key={lang.id} value={lang.value}>
            {lang.name}
          </MenuItem>
        ))}
      </Select>
      <br />
      Information:
      <br />
      {billingIndex === 4 ? (
        <>
          <TextField
            style={{ width: '100%', marginTop: 10 }}
            name="system"
            label="Custom price"
            variant="outlined"
            value={billingPrice}
            helperText={
              <>
                IMPORTANT NOTE: This is the price per month (mmr), not the billed price. <br />
                Set the amount the user's subscription is for.
              </>
            }
            autoComplete="new-name"
            multiline
            rows={1}
            key={'custom-princing-input'}
            onChange={(event) => {
              setBillingPrice(event.target.value ? parseInt(event.target.value) : 0);
            }}
          />
          <TextField
            style={{ width: '100%', marginTop: 10 }}
            name="system"
            label="No. conversations included pr month"
            variant="outlined"
            value={billingConversation}
            helperText="The date the user started their subscription."
            autoComplete="new-name"
            multiline
            rows={1}
            key={'custom-conversation-input'}
            onChange={(event) => {
              setBillingConversation(event.target.value ? parseInt(event.target.value) : 0);
            }}
          />
        </>
      ) : (
        <Box className="stats-panel">
          <ContentPanelField title={'Price'} value={billingOptions[billingIndex].price.toString()} />
          <ContentPanelField title={'No. conversations included pr month'} value={billingOptions[billingIndex].conversations.toString()} />
        </Box>
      )}
      <br />
      Billing start date:
      <br />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={billingDate}
          format="DD-MM-YYYY"
          maxDate={dayjsNow}
          onChange={(value) => {
            setBillingDate(value);
          }}
        />
      </LocalizationProvider>
      <br />
      <h3 style={{ fontSize: 20 }}>Payment intervals:</h3>
      <p style={{ fontSize: 15 }}>Selection of how often the payments are expected for this customer.</p>
      Amount of units:
      <TextField
        style={{ width: '100%', marginTop: 10 }}
        name="system"
        label="Billing interval"
        variant="outlined"
        value={billingIntervalValue}
        helperText="How many units will pass before the payment is due."
        autoComplete="new-name"
        key={'billing-interval-value'}
        onChange={(event) => {
          setBillingIntervalValue(event.target.value ? parseInt(event.target.value) : 0);
        }}
      />
      Unit of billing interval:
      <br />
      <Select
        labelId="Billing-inteval-unit"
        id="basic-menu"
        value={billingIntervalUnit}
        label="billing-interval-unit"
        key="billing-interval-unit-select"
        style={{ marginBottom: 5 }}
        onChange={(event) => {
          setBillingIntervalUnit(event.target.value);
        }}
      >
        {billingIntervalUnits.map((unit: any) => (
          <MenuItem key={unit.value} value={unit.value}>
            {unit.name}
          </MenuItem>
        ))}
      </Select>
      <br />
      <PrimaryButton text={'Save billing information'} handleClick={onSaveClick} />
    </>
  );
}

export default BillingInfo;
