import { Box } from '@mui/material';
import React from 'react';

interface Props {
  title: string;
  value: string;
}

function ContentPanelField({ title, value }: Props) {
  return (
    <>
      <Box className="stats-panel__item">
        <Box className="stats-panel__item-value">{value}</Box>
        <Box className="stats-panel__item-label">{title}</Box>
        <Box className="stats-panel__item-change"></Box> {/*There was a "-" in there, idk why*/}
      </Box>
    </>
  );
}

export default ContentPanelField;
