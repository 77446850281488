import { useEffect } from 'react';

import { useAppSelector, useAppDispatch } from '../../hooks/redux';

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import getDatastreams from '../../api/get-datastreams';
import DatastreamListItem from './DatastreamListItem';

const DatastreamList = () => {
  const dispatch = useAppDispatch();
  const organization = useAppSelector((state) => state.organization);
  const datastreams = useAppSelector((state) => state.datastreams);
  useEffect(() => {
    if (!datastreams && organization) {
      const getDatastreamThunk = getDatastreams(
        {
          uid: organization?.id,
        },
        dispatch
      );

      dispatch(getDatastreamThunk);
    }
  }, [datastreams, organization]);

  return (
    <Grid item xs={12} md={6} className="datastream-list">
      Datastreams:
      <List>
        {datastreams &&
          datastreams?.map((item: any) => {
            return <DatastreamListItem key={item.id} item={item} />;
          })}
      </List>
    </Grid>
  );
};

export default DatastreamList;
