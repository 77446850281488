import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../hooks/redux';

import LeftPanel from '../components/sections/left-panel';
import ContentPanel from '../components/sections/content-panel';

import getUser from '../api/get-user';

import getDatastream from '../api/get-datastream';
import BucketLink from '../components/datastreams/BucketLink';

const Datastream = () => {
  const dispatch = useAppDispatch();
  const { org_id } = useParams();
  const { datastream_id } = useParams();
  const datastream = useAppSelector((state) => state.datastream);
  const organization = useAppSelector((state) => state.organization);

  useEffect(() => {
    if (org_id && !organization) {
      const getUserThunk = getUser({ uid: org_id }, dispatch);

      dispatch(getUserThunk);
    }
  }, [org_id]);

  useEffect(() => {
    if (datastream_id && organization) {
      const getDatastreamThunk = getDatastream({ datastream: datastream_id, owner: organization?.id }, dispatch);

      dispatch(getDatastreamThunk);
    }
  }, [datastream_id, organization]);

  return (
    <div className="app conversation">
      <LeftPanel />
      <ContentPanel title={organization?.metadata?.company_name}>
        <BucketLink />
      </ContentPanel>
    </div>
  );
};

export default Datastream;
