import { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../hooks/redux';
import getEngagement from '../../api/get-engagement';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import ContentPanelCollection from './ContentPanelCollection';
import BillingInfo from '../../pages/BillingInfo';
import NextBilling from './NextBilling';
import dayjs from 'dayjs';
import CollectData from '../datastreams/CollectData';
import DatastreamList from '../datastreams/DatastreamList';
import ModelList from '../models/ModelList';
import UserSetting from '../settings/UserSetting';

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props: { children: React.ReactNode; value: any; index: any }) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Info = ({ uid }: { uid: string }) => {
  const dispatch = useAppDispatch();
  const engagement = useAppSelector((state) => state.engagement);
  const organization = useAppSelector((state) => state.organization);

  const date = new Date();
  const thisYear = String(date.getFullYear());
  const thisMonth = String(date.getMonth() + 1).padStart(2, '0');
  const thisDate = String(date.getDate()).padStart(2, '0');
  const lastMonth = String(date.getMonth()).padStart(2, '0');

  let thisYearPath = thisYear;
  let thisMonthPath = `${thisYear}/months/${thisMonth}`;
  let thisDatePath = `${thisYear}/months/${thisMonth}/dates/${thisDate}`;
  let lastMonthPath = `${thisYear}/months/${lastMonth}`;

  if (uid && uid !== '') {
    thisYearPath = `${thisYear}/users/${uid}`;
    thisMonthPath = `${thisYear}/months/${thisMonth}/users/${uid}`;
    thisDatePath = `${thisYear}/months/${thisMonth}/dates/${thisDate}/users/${uid}`;
    lastMonthPath = `${thisYear}/months/${lastMonth}/users/${uid}`;
  }

  useEffect(() => {
    const runEngagementCall = () => {
      const getEngagementThunk = getEngagement(
        {
          docs: JSON.stringify([thisYearPath, thisMonthPath, thisDatePath, lastMonthPath]),
        },
        dispatch
      );
      dispatch(getEngagementThunk);
    };

    runEngagementCall();
    setInterval(() => {
      runEngagementCall();
    }, 30000);
  }, [thisYearPath, thisMonthPath, thisDatePath]);

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <Tabs value={value} onChange={handleChange} aria-label="info-tabs">
        <Tab label="General" {...a11yProps(0)} />
        <Tab label="Crawler" {...a11yProps(1)} />
        <Tab label="Billing Info" {...a11yProps(2)} />
        <Tab label="User Setting" {...a11yProps(3)} />
      </Tabs>

      {/* General Statistics Panel */}
      <TabPanel value={value} index={0}>
        <ContentPanelCollection title={'Amount of conversations'} value={[engagement[thisDatePath]?.conversations, engagement[thisMonthPath]?.conversations, engagement[lastMonthPath]?.conversations, engagement[thisYearPath]?.conversations]} />
        <ContentPanelCollection title={'Amount of messages'} value={[engagement[thisDatePath]?.messages, engagement[thisMonthPath]?.messages, engagement[lastMonthPath]?.messages, engagement[thisYearPath]?.messages]} />
        <ContentPanelCollection
          title={'Conversation length'}
          value={[(engagement[thisDatePath]?.messages / engagement[thisDatePath]?.conversations)?.toFixed(2), (engagement[thisMonthPath]?.messages / engagement[thisMonthPath]?.conversations)?.toFixed(2), (engagement[lastMonthPath]?.messages / engagement[lastMonthPath]?.conversations)?.toFixed(2), (engagement[thisYearPath]?.messages / engagement[thisYearPath]?.conversations)?.toFixed(2)]}
        />

        <ContentPanelCollection
          title={'Clickrate'}
          value={[
            ((100 * engagement[thisDatePath]?.message_link_click) / engagement[thisDatePath]?.conversations).toFixed(2) + '%',
            ((100 * engagement[thisMonthPath]?.message_link_click) / engagement[thisMonthPath]?.conversations).toFixed(2) + '%',
            ((100 * engagement[lastMonthPath]?.message_link_click) / engagement[lastMonthPath]?.conversations).toFixed(2) + '%',
            ((100 * engagement[thisYearPath]?.message_link_click) / engagement[thisYearPath]?.conversations).toFixed(2) + '%',
          ]}
        />

        <ContentPanelCollection
          title="Token usage cost"
          value={[
            `${engagement[thisDatePath]?.total_token_cost_dkk} DKK / ${engagement[thisDatePath]?.total_token_cost_usd} USD`,
            `${engagement[thisMonthPath]?.total_token_cost_dkk} DKK / ${engagement[thisMonthPath]?.total_token_cost_usd} USD`,
            `${engagement[lastMonthPath]?.total_token_cost_dkk} DKK / ${engagement[lastMonthPath]?.total_token_cost_usd} USD`,
            `${engagement[thisYearPath]?.total_token_cost_dkk} DKK / ${engagement[thisYearPath]?.total_token_cost_usd} USD`,
          ]}
        />

        <ContentPanelCollection
          title="Token conversation avg."
          value={[
            (engagement[thisDatePath]?.total_tokens / engagement[thisDatePath]?.conversations)?.toFixed(0),
            (engagement[thisMonthPath]?.total_tokens / engagement[thisMonthPath]?.conversations)?.toFixed(0),
            (engagement[lastMonthPath]?.total_tokens / engagement[lastMonthPath]?.conversations)?.toFixed(0),
            (engagement[thisYearPath]?.total_tokens / engagement[thisYearPath]?.conversations)?.toFixed(0),
          ]}
        />

        <ContentPanelCollection
          title={'Conversation Rating'}
          value={[
            (((engagement[thisDatePath]?.rate_conversation_1 || 0) + (engagement[thisDatePath]?.rate_conversation_2 * 2 || 0) + (engagement[thisDatePath]?.rate_conversation_3 * 3 || 0) + (engagement[thisDatePath]?.rate_conversation_4 * 4 || 0) + (engagement[thisDatePath]?.rate_conversation_5 * 5 || 0)) / engagement[thisDatePath]?.rate_conversation || 0).toFixed(2), // Today's rating

            (((engagement[thisMonthPath]?.rate_conversation_1 || 0) + (engagement[thisMonthPath]?.rate_conversation_2 * 2 || 0) + (engagement[thisMonthPath]?.rate_conversation_3 * 3 || 0) + (engagement[thisMonthPath]?.rate_conversation_4 * 4 || 0) + (engagement[thisMonthPath]?.rate_conversation_5 * 5 || 0)) / engagement[thisMonthPath]?.rate_conversation || 0).toFixed(2), // This month's rating

            (((engagement[lastMonthPath]?.rate_conversation_1 || 0) + (engagement[lastMonthPath]?.rate_conversation_2 * 2 || 0) + (engagement[lastMonthPath]?.rate_conversation_3 * 3 || 0) + (engagement[lastMonthPath]?.rate_conversation_4 * 4 || 0) + (engagement[lastMonthPath]?.rate_conversation_5 * 5 || 0)) / engagement[lastMonthPath]?.rate_conversation || 0).toFixed(2), // Last month's rating

            (((engagement[thisYearPath]?.rate_conversation_1 || 0) + (engagement[thisYearPath]?.rate_conversation_2 * 2 || 0) + (engagement[thisYearPath]?.rate_conversation_3 * 3 || 0) + (engagement[thisYearPath]?.rate_conversation_4 * 4 || 0) + (engagement[thisYearPath]?.rate_conversation_5 * 5 || 0)) / engagement[thisYearPath]?.rate_conversation || 0).toFixed(2), // This year's rating
          ]}
        />

        <ContentPanelCollection title="Mails sent" value={[engagement[thisDatePath]?.send_email, engagement[thisMonthPath]?.send_email, engagement[lastMonthPath]?.send_email, engagement[thisYearPath]?.send_email]} />
      </TabPanel>

      {/* Crawler */}
      <TabPanel value={value} index={1}>
        <CollectData />
        <ModelList />
        <DatastreamList />
      </TabPanel>

      {/* Billing Info Panel */}
      <TabPanel value={value} index={2}>
        {uid ? <BillingInfo organization={organization} /> : <NextBilling title={'Billing information'} description={'Here, the surrounding months expected cashflow is shown, as well as our general Monthly Recurring Revenue (MRR)'} values={[dayjs(), 2]} />}
      </TabPanel>

      <TabPanel value={value} index={3}>
        <Typography variant="h6" gutterBottom>
          User Settings
        </Typography>
        <UserSetting label="Karla Settings" field="karla_settings" uid={uid} />
      </TabPanel>
    </div>
  );
};

export default Info;
