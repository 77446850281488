import { Link } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../hooks/redux';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SnippetFolderRoundedIcon from '@mui/icons-material/SnippetFolderRounded';

const DatastreamListItem = ({ item }: any) => {
  const organization = useAppSelector((state) => state.organization);
  return (
    <Link to={`/customers/${organization?.id}/datastream/${item?.id}`} state={{ datastream: item }}>
      <ListItem>
        <ListItemIcon>
          <SnippetFolderRoundedIcon />
        </ListItemIcon>
        <ListItemText primary={item?.name} secondary={'Secondary text'} />
      </ListItem>
    </Link>
  );
};

export default DatastreamListItem;
