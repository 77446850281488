import { useEffect } from 'react';

import { useAppSelector, useAppDispatch } from '../../hooks/redux';
import { getCollection, getDocumentList } from '../../hooks/firestore';
import getUsers from '../../api/get-users';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import OrganizationListItem from './OrganizationListItem';

const OrganizationList = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const models = useAppSelector((state) => state.models);
  const organizations = useAppSelector((state) => state.organizations);

  useEffect(() => {
    if (!organizations) {
      const getUsersThunk = getUsers({}, dispatch);

      dispatch(getUsersThunk);
    }
  }, [organizations]);

  // useEffect(() => {
  //   if (!organizations) {
  //     const fetch = async () => {
  //       const response = await getCollection('users');

  //       if (response) {
  //         dispatch({ type: 'SET_ORGANIZATIONS', payload: response });
  //       }
  //     };

  //     fetch();
  //   }
  // }, [organizations]);

  return (
    <Grid item xs={12} md={6} className="model-list">
      <List>
        {organizations &&
          organizations?.map((item: any) => {
            return <OrganizationListItem organization={item} />;
          })}
      </List>
    </Grid>
  );
};

export default OrganizationList;
