import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../hooks/redux';
import { getDocument, updateDocument } from '../hooks/firestore';

import LeftPanel from '../components/sections/left-panel';
import ContentPanel from '../components/sections/content-panel';

import Messages from '../components/conversations/Messages';
import Info from '../components/conversations/Info';

import getModel from '../api/get-model';
import getUser from '../api/get-user';
import CollectData from '../components/datastreams/CollectData';
import TrainModel from '../components/models/TrainModel';
import PrimaryButton from '../components/button/PrimaryButton';
import Customer from './Customer';

const Model = () => {
  const dispatch = useAppDispatch();
  const { org_id } = useParams();
  const { model_id } = useParams();
  const organization = useAppSelector((state) => state.organization);

  useEffect(() => {
    if (org_id && !organization) {
      const getUserThunk = getUser({ uid: org_id }, dispatch);

      dispatch(getUserThunk);
    }
  }, [org_id]);

  useEffect(() => {
    if (model_id && organization) {
      const getUserThunk = getModel({ model: model_id, owner: organization?.id }, dispatch);

      dispatch(getUserThunk);
    }
  }, [model_id, organization]);

  return (
    <div className="app conversation">
      <LeftPanel />
      <ContentPanel title={organization?.metadata?.company_name}>
        <TrainModel />
      </ContentPanel>
    </div>
  );
};

export default Model;
