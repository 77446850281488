import axios from 'axios';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { API_ENDPOINTS } from '../utils/constants';

interface SetLoadingAction extends AnyAction {
  type: 'SET_IS_LOADING';
  payload: boolean;
}

interface DisplayErrorAction extends AnyAction {
  type: 'DISPLAY_ERROR';
  payload: string;
}

interface UpdateUserAction extends AnyAction {
  type: 'UPDATE_USER_SUCCESS';
  payload: Promise<any>;
}

type ActionTypes = SetLoadingAction | DisplayErrorAction | UpdateUserAction;

const updateUser = (payload: { uid: string; data: any; existingData: any }): ThunkAction<Promise<any>, any, any, ActionTypes> => {
  return async function updateUserThunk(dispatch) {
    dispatch({ type: 'SET_IS_LOADING', payload: true });

    try {
      const response = await axios.post(`${API_ENDPOINTS.KARLA_FLASK_API}/karla/operations/users/update`, {
        params: {
          uid: payload.uid,
          data: payload.data,
        },
      });

      if (response.data && response.data.type === 'success') {
        dispatch({
          type: 'UPDATE_USER_SUCCESS',
          payload: Promise.resolve(response.data),
        });
        return response.data;
      } else {
        throw new Error(`Error from server: ${response.data.reason}`);
      }
    } catch (error: unknown) {
      let errorMessage = 'An unknown error occurred';
      if (error instanceof Error) {
        errorMessage = error.message;
      }

      dispatch({
        type: 'DISPLAY_ERROR',
        payload: errorMessage,
      });

      throw error;
    } finally {
      dispatch({ type: 'SET_IS_LOADING', payload: false });
    }
  };
};

export default updateUser;
