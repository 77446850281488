import { Link } from 'react-router-dom';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FolderIcon from '@mui/icons-material/Folder';

const OrganizationListItem = ({ organization }: any) => {
  return (
    <Link to={`/customers/${organization?.id}`} state={{ customer: organization }}>
      <ListItem>
        <ListItemIcon>
          <FolderIcon />
        </ListItemIcon>
        <ListItemText primary={organization?.metadata?.company_name} secondary={organization?.metadata?.country} />
      </ListItem>
    </Link>
  );
};

export default OrganizationListItem;
