import { updateDocument } from '../hooks/firestore';

interface ModelReducerProps {
  type: 'SET_ORGANIZATION' | 'GET_ORGANIZATION' | 'UPDATE_ORGANIZATION';
  payload: any;
}

const modelReducer = (state = false, action: ModelReducerProps) => {
  switch (action.type) {
    case 'SET_ORGANIZATION':
      return action.payload;
    case 'GET_ORGANIZATION':
      return state;
    case 'UPDATE_ORGANIZATION':
      if (action.payload?.id) {
        updateDocument('users', action?.payload?.id, action.payload);
      }
      return state;
    default:
      return state;
  }
};

export default modelReducer;
